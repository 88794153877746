@use "global" as g;

.awards{
    padding: 120px 0 80px;
    overflow-y: hidden;
    background-color: g.$bg_color02;

    &__inner{
        margin-top: 60px;
        width: 100%;
        position: relative;
        text-align: center;

        &::before{
            content: "";
            width: 200px;
            height: 368px;
            position: absolute;
            top: 0;
            left: 0;
            background: url('../img/pc/about/l-works-bg.png') no-repeat center center / cover;

            @include g.mq(sp){
                display: none;
            }
        }

        &::after{
            content: "";
            width: 200px;
            height: 368px;
            position: absolute;
            top: 0;
            right: 0;
            background: url('../img/pc/about/r-works-bg.png') no-repeat center center / cover;


            @include g.mq(sp){
                display: none;
            }
        }
    }

    &__item{
        &:last-child{
            margin-top: 60px;

            @include g.mq(sp){
                margin-top: 45px;
            }
        }
    }

    &__heading{
        display: inline-block;
        padding-left: 40px;
        font-size: g.$xs_fsz;
        position: relative;

        &::before{
            content: "";
            position: absolute;
            top: 50%;
            left: 0px;
            width: 29px;
            height: 20px;
            transform: translateY(-50%);
            background: url('../img/pc/about/okan.png') no-repeat center center / cover;
        }
    }

    &__txt-inner{
        margin-top: 30px;

        @include g.mq(sp){
            margin-top: 20px;
        }
    }

    &__txt{
        font-size: g.$md_fsz;
        font-weight: g.$fw_bold;
        line-height: 1.4;

        @include g.mq(tab){
            font-size: 2rem;
        }

        @include g.mq(sp){
            font-size: g.$sm_fsz;
        }

        &:not(:first-child){
            margin-top: 14px;

            @include g.mq(sp){
                margin-top: 10px;
            }
        }
    }

    &__img-inner{
        display: inline-flex;
        justify-content: center;
        margin-top: 80px;
        margin-bottom: -100px;

        @include g.mq(sp){
            margin-bottom: -140px;
        }
    }

    &__img{
        width: 25%;

        @include g.mq(sp){
            width: 48%;
        }
    }
}