@use "global" as g;

.thought{
    padding: 80px 0 90px;
    background: url('../img/pc/top/thought-img.jpg') no-repeat center left 30% / cover;
    position: relative;
    z-index: 1;

    @include g.mq(tab){
        background: url('../img/pc/top/thought-img.jpg') no-repeat center left 30% / cover;
    }

    @include g.mq(sp){
        background: url('../img/sp/top/thought-img.png') no-repeat center center / cover;
    }

    &::after{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 2;
    }


    &__inner{
        width: 60%;
        text-align: center;
        padding: 30px 30px 30px;
        margin-left: auto;
        position: relative;
        z-index: 3;
        margin-right: 60px;

        @include g.mq(tab){
            padding: 0;
        }

        @include g.mq(sp){
            width: 100%;
            padding: 0;
        }
    }

    &__ttl{
        font-size: 3.2rem;
        color: g.$wh_color;
        text-align: center;

        @include g.mq(tab){
            font-size: 2.8rem;
        }

        @include g.mq(sp){
            font-size: 2.6rem;
        }

        span{
            display: block;
            color: g.$gr_color;
            font-size: g.$md_fsz;
            margin-bottom: 6px;
        }
    }

    &__lead{
        margin-top: 30px;
        font-size: g.$sm_fsz;
        color: g.$wh_color;
        line-height: 1.8;
        text-align: left;

        @include g.mq(tab){
            font-size: g.$xs_fsz;
        }

        @include g.mq(sp){
            font-size: g.$xs_fsz;
        }

        span{
            display: inline-block;
            margin-top: 40px;

            @include g.mq(sp){
                margin-top: 25px;
            }
        }
    }
}