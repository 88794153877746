@use "global" as g;

#loader-bg {
    opacity: 1;
    visibility: visible;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background: g.$bg_color01;
    z-index: 1000;

    &.is-active{
        opacity: 0;
        visibility: hidden;
    }
}

#loader{
    width: 100%;
    height: 100%;
    // display: flex;
    // align-items: center;


}

#logo-inner{
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    height: 200px;
}

#loader-logo{
    position: absolute;
    top: 0;
    left: 100px;
    width: 200px;
    transition: left 1s;
    animation: logo 1.1s ease-in-out infinite;
}

#load-txt{
    position: relative;
    top: 51%;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: g.$md_fsz;
}

@keyframes logo{
    0%{ left: 100px; }
    100%{ left: -100px;}
}