@use "global" as g;


.tab-items{
    margin-top: 30px;

    ul{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        position: relative;
        margin-bottom: -1px;
    }
}

.tab-item{
    border: 1px solid g.$gr_color;
    border-radius: 12px 12px 0 0;
    background-color: g.$gr_color;
    color: g.$wh_color;
    width: 33.3%;
    height: 58px;
    text-align: center;
    line-height: 58px;
    font-size: g.$sm_fsz;
    transition: height 0.3s;    // つけなくても良い
    cursor: pointer;

    @include g.mq(xs){
        font-size: g.$xs_fsz;
    }

    &.active{
        height: 68px;
        line-height: 68px;
        background-color: g.$wh_color;
        color: g.$gr_color;
        font-size: 20px;

        @include g.mq(xs){
            font-size: 1.6rem;
        }
    }
}


.tab-text{
    display: none;
    padding: 30px;
    background-color: g.$wh_color;
    border: 1px solid g.$gr_color;

    &.active{
        display: block;
    }
}


.tab-heading{
    font-size: 24px;
    font-weight: g.$fw_bold;
    color: g.$gr_color;
    line-height: 1.5;

    @include g.mq(sp){
        font-size: 2.2rem;
    }
}

.tab-container{
    display: flex;
    justify-content: space-between;

    @include g.mq(sp){
        display: block;
    }
}

.tab-img{
    width: 24%;
    border: 2px solid g.$bk_color;

    @include g.mq(sp){
        width: 75%;
        margin: 0 auto;
    }
}

.tab-txt-inner{
    width: 70%;

    @include g.mq(sp){
        width: 100%;
        margin-top: 20px;
    }
}

.tab-txt-lead{
    font-size: g.$xs_fsz;
    line-height: 1.5;
    padding: 30px 0 35px;
    border-bottom: 3px dashed g.$gr_color;

    @include g.mq(xs){
        padding: 15px 0 35px;
    }

    span{
        color: #e95383;
        font-weight: g.$fw_bold;
    }
}

.tab-lists{
    margin-top: 30px;
}

.tab-lists-item-inner{
    display: flex;

    @include g.mq(xs){
        display: block;
    }

    p{
        @include g.mq(xs){
            margin-top: 15px;
        }
    }

    &:last-child{
        margin-top: 15px;

        @include g.mq(xs){
            margin-top: 0;
        }
    }

    p{
        font-size: g.$xs_fsz;
        &:first-child{
            margin-right: 60px;

            @include g.mq(sp){
                margin-right: 0px;
            }
        }
    }
}

