@charset "UTF-8";
/*================================================================
Chrome/Firefox/Edgeなど、各ブラウザはそれぞれデフォルトで効くCSSを持っています。
何もしないと見え方が微妙に変わるので、デフォルトのCSSをリセットして
ブラウザごとの表示の差異をなくすために書くのが「リセットCSS」です。
================================================================*/
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html, body, h1, h2, h3, h4, h5, h6, ul, ol, dl, li, dt, dd, p, div, span, img, a, table, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: normal;
  font-size: 100%;
  vertical-align: baseline;
}

header, footer, nav, section, article, aside, figure, figcaption {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
  list-style-type: none;
}

html, body {
  font-size: 62.5%;
}

body {
  font-family: "Noto Sans JP", sans-serif;
  color: #333;
  background-color: #fcf9f1;
  position: relative;
  min-height: 100vh;
  padding-bottom: 144px;
  box-sizing: border-box;
}

li {
  list-style: none;
}

img {
  width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  color: #333;
  cursor: pointer;
}

a:hover {
  opacity: 0.7;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}

.l-container {
  width: 100%;
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 40px;
}
@media screen and (max-width: 767px) {
  .l-container {
    padding: 0 20px;
  }
}

.l-footer {
  background-color: #dae7c1;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.c-drawer-icon {
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 200;
  display: inline-block;
  transition: all 0.5s ease 0s;
  opacity: 1;
  padding: 30px 30px;
  border-radius: 0 0 0 10px;
  background-color: #5f9d7a;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .c-drawer-icon {
    padding: 25px;
  }
}
.c-drawer-icon.-js-change {
  top: 20px;
  right: 40px;
}
@media screen and (max-width: 767px) {
  .c-drawer-icon.-js-change {
    right: 20px;
  }
}
.c-drawer-icon.is-active .c-drawer-icon__bar1 {
  transform: rotate(-45deg);
  top: 8px;
}
.c-drawer-icon.is-active .c-drawer-icon__bar2 {
  display: none;
}
.c-drawer-icon.is-active .c-drawer-icon__bar3 {
  transform: rotate(45deg);
  top: 8px;
}
.c-drawer-icon__bars {
  width: 22px;
  height: 20px;
  display: block;
  position: relative;
}
.c-drawer-icon__bar1, .c-drawer-icon__bar2, .c-drawer-icon__bar3 {
  position: absolute;
  width: 22px;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
}
.c-drawer-icon__bar1.is-active, .c-drawer-icon__bar2.is-active, .c-drawer-icon__bar3.is-active {
  background-color: #5f9d7a;
}
.c-drawer-icon__bar1 {
  top: 0;
}
.c-drawer-icon__bar2 {
  top: 8px;
}
.c-drawer-icon__bar3 {
  top: 16px;
}

.c-drawer {
  width: 60%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 130;
  background: #dae7c1;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.16);
  transform: translateX(110%);
  transition: transform 0.5s ease 0s;
  overflow-y: scroll;
}
.c-drawer.is-active {
  transform: translateX(0);
}
@media screen and (max-width: 767px) {
  .c-drawer {
    width: 100%;
  }
}
.c-drawer__list {
  padding: 100px 0 60px;
  text-align: center;
  background-color: #fcf9f1;
}
@media screen and (max-width: 767px) {
  .c-drawer__list {
    padding: 60px 0 45px;
  }
}
.c-drawer__item:last-child {
  margin-top: 30px;
}
.c-drawer__link {
  display: block;
  font-size: 1.8rem;
  padding: 20px;
  position: relative;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .c-drawer__link {
    font-size: 1.6rem;
  }
}

.c-drawer-background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 120;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: none;
}
.c-drawer-background.is-active {
  display: block;
}

.c-drawer-sns__items {
  width: 80%;
  margin: 0 auto;
  flex-wrap: wrap;
  background-color: #dae7c1;
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .c-drawer-sns__items {
    padding-top: 30px;
    width: 95%;
  }
}
.c-drawer-sns__item {
  display: inline-flex;
  align-items: center;
  margin-top: 20px;
  font-size: 1.8rem;
  width: 33%;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .c-drawer-sns__item {
    width: 50%;
  }
}
@media screen and (max-width: 767px) {
  .c-drawer-sns__item {
    width: 33%;
  }
}
@media screen and (max-width: 500px) {
  .c-drawer-sns__item {
    width: 50%;
  }
}
.c-drawer-sns__item-img {
  width: 40px;
  margin-right: 20px;
}

.c-totop {
  display: inline-block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: fixed;
  z-index: 100;
  bottom: 24px;
  right: 24px;
  background-color: #5f9d7a;
}
.c-totop__img {
  width: 55px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 14px;
}
.c-totop__txt {
  display: block;
  text-align: center;
  margin-top: 10px;
  font-size: 1.4rem;
  color: #fff;
}

.c-ttl {
  font-size: 3.6rem;
  letter-spacing: 3.6px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .c-ttl {
    font-size: 3rem;
  }
}
.c-ttl .br {
  display: block;
  margin-top: 6px;
}
.c-ttl.about {
  border-bottom: 2px dashed #5f9d7a;
  padding-bottom: 10px;
  display: inline-block;
}
.c-ttl span {
  color: #5f9d7a;
  font-weight: 700;
  font-size: 4rem;
  -webkit-text-stroke: 0.6px #fff;
}
@media screen and (max-width: 767px) {
  .c-ttl span {
    font-size: 3.4rem;
  }
}

.c-sub-ttl {
  font-size: 2.4rem;
  font-weight: 700;
  text-align: center;
  color: #5f9d7a;
}

.c-btn {
  display: inline-block;
  padding: 14px 60px;
  font-size: 1.6rem;
  color: #fff;
  background-color: #5f9d7a;
  border-radius: 20px;
  border: 2px solid #5f9d7a;
  transition: background 0.3s ease 0s;
}
.c-btn:hover {
  color: #333;
  background-color: #fff;
}

@keyframes anime {
  0% {
    opacity: 0;
  }
  33% {
    opacity: 1;
  }
  66% {
    opacity: 0;
    transform: scale(1.2);
    z-index: 9;
  }
  100% {
    opacity: 0;
  }
}
.mv {
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.mv__bg {
  width: 100%;
  height: 100vh;
  z-index: 10;
  opacity: 0;
  background: url("../img/pc/top/mv1.jpg") no-repeat center center/cover;
  position: absolute;
  left: 0;
  top: 0;
  animation: anime 24s 0s infinite;
}
@media screen and (max-width: 767px) {
  .mv__bg {
    background: url("../img/sp/top/mv1.png") no-repeat center center/cover;
  }
}
.mv__bg:nth-child(2) {
  animation-delay: 8s;
  background: url("../img/pc/top/mv2.jpg") no-repeat center center/cover;
}
@media screen and (max-width: 767px) {
  .mv__bg:nth-child(2) {
    background: url("../img/sp/top/mv2.png") no-repeat center center/cover;
  }
}
.mv__bg:nth-child(3) {
  animation-delay: 16s;
  background: url("../img/pc/top/mv3.jpg") no-repeat center center/cover;
}
@media screen and (max-width: 767px) {
  .mv__bg:nth-child(3) {
    background: url("../img/sp/top/mv3.png") no-repeat center center/cover;
  }
}
.mv__ttl {
  position: relative;
  z-index: 20;
  text-align: center;
  margin-top: 60px;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .mv__ttl {
    width: 95%;
    margin: 60px auto;
  }
  .mv__ttl #ryomai {
    width: 100%;
    margin: 0 auto;
  }
}
.mv__dot {
  font-size: 6.6rem;
  font-weight: 700;
  -webkit-text-stroke: 2px #fff;
}
.mv__strong {
  display: block;
  font-size: 6.6rem;
  font-weight: 700;
  color: #5f9d7a;
  text-shadow: -2px -2px #fff;
  -webkit-text-stroke: 0;
}

.top-about {
  text-align: center;
  padding: 120px 0 80px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .top-about {
    padding: 120px 0 180px;
  }
}
.top-about__item {
  margin-top: 150px;
  position: relative;
}
@media screen and (max-width: 767px) {
  .top-about__item {
    margin-top: 45px;
  }
}
.top-about__item:nth-child(4) {
  margin-top: 0;
}
.top-about__item:nth-child(4) .top-about__txt {
  position: relative;
  margin-left: auto;
  z-index: 1;
  width: 41.8%;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 4px 3px #ddcfb2;
  padding: 8px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .top-about__item:nth-child(4) .top-about__txt {
    width: 60%;
  }
}
@media screen and (max-width: 767px) {
  .top-about__item:nth-child(4) .top-about__txt {
    width: 100%;
  }
}
.top-about__item:nth-child(4) .top-about__img {
  width: 65%;
  position: absolute;
  top: -90px;
  left: 0;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .top-about__item:nth-child(4) .top-about__img {
    width: 45%;
    top: 60px;
  }
}
@media screen and (max-width: 767px) {
  .top-about__item:nth-child(4) .top-about__img {
    display: none;
  }
}
.top-about__txt {
  position: relative;
  z-index: 1;
  width: 41.8%;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 4px 3px #ddcfb2;
  padding: 8px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .top-about__txt {
    width: 60%;
  }
}
@media screen and (max-width: 767px) {
  .top-about__txt {
    width: 100%;
  }
}
.top-about__line {
  border: 3px dashed #5f9d7a;
  border-radius: 20px;
  padding: 30px 60px;
}
@media screen and (max-width: 767px) {
  .top-about__line {
    padding: 30px;
  }
}
.top-about__txt-ttl {
  text-align: center;
}
.top-about__sub-ttl {
  display: inline-block;
  font-size: 1.8rem;
  position: relative;
}
.top-about__sub-ttl::before {
  content: "";
  display: inline-block;
  position: absolute;
  bottom: 8px;
  left: -25px;
  transform: rotate(55deg);
  width: 18px;
  height: 1px;
  background-color: #333;
}
.top-about__sub-ttl::after {
  content: "";
  display: inline-block;
  position: absolute;
  bottom: 8px;
  right: -25px;
  transform: rotate(125deg);
  width: 18px;
  height: 1px;
  background-color: #333;
}
.top-about__ttl-strong {
  display: block;
  font-size: 2.2rem;
  font-weight: 700;
  color: #5f9d7a;
  margin-top: 10px;
}
.top-about__body {
  margin-top: 28px;
}
.top-about__lead {
  font-size: 1.6rem;
  letter-spacing: 1.6px;
  line-height: 1.5;
  text-align-last: left;
}
.top-about__btn-inner {
  margin-top: 35px;
  text-align: center;
}
.top-about__img {
  width: 65%;
  position: absolute;
  top: -90px;
  right: 0;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .top-about__img {
    width: 45%;
  }
}
@media screen and (max-width: 767px) {
  .top-about__img {
    display: none;
  }
}
.top-about__img .pc {
  display: block;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .top-about__img .pc {
    display: none;
  }
}
.top-about__img .sp {
  display: none;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .top-about__img .sp {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .top-about__img .sp {
    display: none;
  }
}
.top-about__bg {
  width: 58%;
  margin: 0 auto;
}

.example {
  background: url("../img/pc/top/gr-bg.png") no-repeat center center/cover;
  display: flex;
  justify-content: center;
  padding: 170px 0 80px;
  position: relative;
  z-index: 2;
  top: 10px;
}
@media screen and (max-width: 767px) {
  .example {
    background: url("../img/sp/top/gr-bg.png") no-repeat center center/cover;
    padding: 140px 0 80px;
  }
}
.example__inner {
  width: 57%;
  margin: 0 auto;
  position: relative;
  border: 3px dashed #5f9d7a;
  border-radius: 20px;
  text-align: center;
  padding: 60px 0 150px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .example__inner {
    width: 100%;
    margin: 0 60px;
    padding: 30px 15px 100px;
  }
}
@media screen and (max-width: 767px) {
  .example__inner {
    width: 100%;
    margin: 0 20px;
    padding: 30px 15px 100px;
  }
}
.example__inner::before {
  content: "";
  position: absolute;
  top: -75px;
  left: -30px;
  width: 114px;
  height: 97px;
  display: inline-block;
  background: url("../img/icon/illustration01.png") no-repeat center center/cover;
}
@media screen and (max-width: 500px) {
  .example__inner::before {
    display: none;
  }
}
.example__inner::after {
  content: "";
  position: absolute;
  bottom: 0px;
  right: -30px;
  width: 114px;
  height: 97px;
  display: inline-block;
  background: url("../img/icon/illustration02.png") no-repeat center center/cover;
}
@media screen and (max-width: 500px) {
  .example__inner::after {
    right: -16px;
  }
}
.example__ttl {
  position: relative;
  top: -90px;
  background-color: #eff4e3;
  padding: 0 20px;
  display: inline-block;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .example__ttl {
    top: -60px;
  }
}
@media screen and (max-width: 767px) {
  .example__ttl {
    top: -60px;
  }
}
.example__items {
  justify-content: center;
  width: 160%;
  max-width: 1180px;
  margin: 0 calc(50% - 50vw);
  padding: 0 40px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .example__items {
    width: 100%;
    padding: 0 25px;
  }
}
@media screen and (max-width: 767px) {
  .example__items {
    width: 100%;
    padding: 0;
    margin: -45px calc(50% - 50vw);
  }
}
.example__items-inner {
  display: flex;
  justify-content: space-between;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .example__items-inner {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .example__items-inner {
    display: block;
  }
}
.example__item {
  width: 30%;
  background-color: #fff;
  padding: 60px 34px 70px;
  border-radius: 20px;
  text-align: left;
}
.example__item:nth-of-type(2) {
  position: relative;
  top: 60px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .example__item:nth-of-type(2) {
    top: 0;
  }
}
@media screen and (max-width: 767px) {
  .example__item:nth-of-type(2) {
    top: 0;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .example__item {
    width: 100%;
    margin-top: 30px;
    padding: 30px 15px 45px;
  }
}
@media screen and (max-width: 767px) {
  .example__item {
    width: 100%;
    margin-top: 30px;
    padding: 50px 30px 45px;
  }
}
.example__item-ttl {
  font-size: 2.2rem;
  font-weight: 700;
  color: #5f9d7a;
  line-height: 1.5;
}
.example__company {
  font-size: 2.2rem;
  margin-top: 30px;
}
.example__lead {
  font-size: 1.6rem;
  line-height: 1.5;
  margin-top: 15px;
}
.example__lead span {
  color: #e95383;
  font-weight: 700;
}

.post {
  background: url("../img/pc/top/wood-bg.jpg") no-repeat center center/cover;
  position: relative;
  z-index: 1;
  top: -40px;
  padding: 150px 0 100px;
}
.post__lead {
  margin-top: 60px;
  font-size: 1.6rem;
  line-height: 1.5;
}
.post__items {
  width: 100%;
  max-width: 880px;
  margin: 45px auto 0;
}
.post__item {
  background-color: #fff;
  padding: 20px 20px 30px 20px;
}
@media screen and (max-width: 767px) {
  .post__item {
    padding: 15px 15px 25px 15px;
  }
}
.post__item:nth-child(3) {
  margin-top: 30px;
}
.post__heading {
  margin-top: 30px;
  font-size: 3.6rem;
  font-weight: 700;
  color: #5f9d7a;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .post__heading {
    font-size: 3.2rem;
  }
}
.post__item-txt {
  margin-top: 15px;
  font-size: 2.4rem;
  line-height: 1.5;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .post__item-txt {
    font-size: 1.9rem;
  }
}
.post__item-inner {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
@media screen and (max-width: 767px) {
  .post__item-inner {
    display: block;
  }
}
.post__item-inner .post__item {
  width: 46.6%;
}
@media screen and (max-width: 767px) {
  .post__item-inner .post__item {
    width: 100%;
  }
  .post__item-inner .post__item:nth-child(2) {
    margin-top: 30px;
  }
}

.faq {
  position: relative;
  z-index: 3;
  top: -60px;
  background-color: #fcf9f1;
  padding: 120px 0 80px;
}
@media screen and (max-width: 767px) {
  .faq {
    padding: 80px 0 60px;
  }
}
.faq.-padding {
  padding: 60px 0 0px;
}
@media screen and (max-width: 767px) {
  .faq.-padding {
    padding: 45px 0 0px;
  }
}
.faq__items {
  margin-top: 80px;
}
@media screen and (max-width: 767px) {
  .faq__items {
    margin-top: 45px;
  }
}
.faq__items.-margin {
  margin-top: 0;
}
.faq__item {
  border-bottom: 3px dashed #5f9d7a;
}
.faq__q {
  padding: 30px 60px;
  position: relative;
  font-size: 1.8rem;
  line-height: 1.5;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .faq__q {
    padding: 30px 40px 15px 60px;
    font-size: 1.6rem;
  }
}
.faq__q::before {
  content: "Q";
  position: absolute;
  top: 27px;
  left: 20px;
  font-size: 1.6rem;
  border: 1px solid #5f9d7a;
  border-radius: 50%;
  padding: 2px 9px 5px;
  color: #5f9d7a;
  line-height: 1.5;
}
.faq__icon {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 50%;
  right: 14px;
  transform: translateY(-50%);
}
@media screen and (max-width: 500px) {
  .faq__icon {
    top: 58%;
  }
}
.faq__icon.open .faq__bar2 {
  transform: rotate(90deg);
}
.faq__bar1 {
  width: 14px;
  height: 2px;
  background-color: #5f9d7a;
  border-radius: 20px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.faq__bar2 {
  width: 2px;
  height: 14px;
  background-color: #5f9d7a;
  border-radius: 20px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s ease 0s;
}
.faq__a {
  font-size: 1.8rem;
  line-height: 1.5;
  padding: 15px 60px 30px;
  position: relative;
  letter-spacing: 1px;
  display: none;
}
@media screen and (max-width: 500px) {
  .faq__a {
    font-size: 1.6rem;
  }
}
.faq__a::before {
  content: "A";
  position: absolute;
  top: 16px;
  left: 20px;
  font-size: 1.6rem;
  border: 1px solid #bea574;
  border-radius: 50%;
  padding: 2px 9px;
  color: #bea574;
  line-height: 1.5;
}
.faq__content {
  position: relative;
}
.faq__btn-inner {
  margin-top: 60px;
  text-align: center;
}

.thought {
  padding: 80px 0 90px;
  background: url("../img/pc/top/thought-img.jpg") no-repeat center left 30%/cover;
  position: relative;
  z-index: 1;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .thought {
    background: url("../img/pc/top/thought-img.jpg") no-repeat center left 30%/cover;
  }
}
@media screen and (max-width: 767px) {
  .thought {
    background: url("../img/sp/top/thought-img.png") no-repeat center center/cover;
  }
}
.thought::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2;
}
.thought__inner {
  width: 60%;
  text-align: center;
  padding: 30px 30px 30px;
  margin-left: auto;
  position: relative;
  z-index: 3;
  margin-right: 60px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .thought__inner {
    padding: 0;
  }
}
@media screen and (max-width: 767px) {
  .thought__inner {
    width: 100%;
    padding: 0;
  }
}
.thought__ttl {
  font-size: 3.2rem;
  color: #fff;
  text-align: center;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .thought__ttl {
    font-size: 2.8rem;
  }
}
@media screen and (max-width: 767px) {
  .thought__ttl {
    font-size: 2.6rem;
  }
}
.thought__ttl span {
  display: block;
  color: #5f9d7a;
  font-size: 2.2rem;
  margin-bottom: 6px;
}
.thought__lead {
  margin-top: 30px;
  font-size: 1.8rem;
  color: #fff;
  line-height: 1.8;
  text-align: left;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .thought__lead {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 767px) {
  .thought__lead {
    font-size: 1.6rem;
  }
}
.thought__lead span {
  display: inline-block;
  margin-top: 40px;
}
@media screen and (max-width: 767px) {
  .thought__lead span {
    margin-top: 25px;
  }
}

.cta {
  padding: 120px 0 100px;
}
@media screen and (max-width: 767px) {
  .cta {
    padding: 80px 0 60px;
  }
}
.cta__inner {
  text-align: center;
}
.cta__lead {
  font-size: 1.6rem;
  line-height: 1.5;
  margin-top: 60px;
}
@media screen and (max-width: 767px) {
  .cta__lead {
    margin-top: 45px;
  }
}
.cta__strong {
  font-size: 1.6rem;
  line-height: 1.5;
  margin-top: 50px;
  position: relative;
  display: inline-block;
}
@media screen and (max-width: 767px) {
  .cta__strong {
    margin-top: 40px;
  }
}
.cta__strong::before {
  content: "";
  position: absolute;
  top: 10px;
  left: -50px;
  width: 30px;
  height: 1px;
  background-color: #5f9d7a;
  transform: rotate(50deg);
}
@media screen and (max-width: 767px) {
  .cta__strong::before {
    left: -30px;
  }
}
.cta__strong::after {
  content: "";
  position: absolute;
  top: 10px;
  right: -50px;
  width: 30px;
  height: 1px;
  background-color: #5f9d7a;
  transform: rotate(130deg);
}
@media screen and (max-width: 767px) {
  .cta__strong::after {
    right: -30px;
  }
}
.cta__btn-inner {
  margin-top: 10px;
}

.footer {
  padding: 30px 0;
}
.footer__items {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer__item {
  width: 40px;
  margin-left: 30px;
}
.footer__item:first-child {
  margin-left: 0;
}
.footer__copyright {
  display: block;
  text-align: center;
  font-size: 1.2rem;
  margin-top: 15px;
}

.fv {
  width: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: visible;
  padding: 80px 0 120px;
}
@media screen and (max-width: 767px) {
  .fv {
    padding: 80px 0;
  }
}
.fv .pc {
  display: block;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .fv .pc {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .fv .pc {
    display: none;
  }
}
.fv .sp {
  display: none;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .fv .sp {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .fv .sp {
    display: block;
  }
}
.fv__img {
  width: 75%;
  background: url("") no-repeat center center/cover;
  position: relative;
  z-index: 10;
}
.fv__ttl {
  font-size: 2.4rem;
  font-weight: 700;
  letter-spacing: 2.4px;
  writing-mode: vertical-rl;
  position: absolute;
  top: -50px;
  right: -30px;
  z-index: 1;
  width: 480px;
  height: 480px;
  background-color: #eff4e3;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 140px;
  padding-left: 40px;
  padding-bottom: 60px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .fv__ttl {
    justify-content: right;
  }
}
@media screen and (max-width: 767px) {
  .fv__ttl {
    justify-content: right;
    width: 420px;
    height: 420px;
    font-size: 2rem;
  }
}
@media screen and (max-width: 500px) {
  .fv__ttl {
    font-size: 2rem;
    right: -80px;
    width: 370px;
    height: 370px;
    padding-bottom: 0px;
  }
}
.fv__ttl span {
  display: block;
  font-size: 1.6rem;
  color: #5f9d7a;
  margin-left: 14px;
  letter-spacing: 1.6px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .fv__ttl span {
    margin-right: 100px;
  }
}
@media screen and (max-width: 767px) {
  .fv__ttl span {
    margin-right: 100px;
    margin-left: 7px;
  }
}
.fv__en-ttl {
  position: absolute;
  top: 60%;
  right: 15%;
  z-index: 13;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
}
@media screen and (max-width: 767px) {
  .fv__en-ttl {
    width: 120px;
    height: 120px;
    right: 20%;
  }
}
@media screen and (max-width: 500px) {
  .fv__en-ttl {
    display: none;
  }
}
.fv__en-ttl.-about::after {
  content: "ABOUT US";
  position: absolute;
  display: inline-block;
  width: 300px;
  top: 30px;
  left: -50%;
  font-size: 4.8rem;
  font-weight: 700;
  letter-spacing: 4.8px;
  color: #dae7c1;
  z-index: 15;
}
@media screen and (max-width: 767px) {
  .fv__en-ttl.-about::after {
    top: 60px;
    font-size: 3rem;
    left: -30%;
  }
}
.fv__en-ttl.-service::after {
  content: "WHAT WE DO";
  position: absolute;
  display: inline-block;
  width: 355px;
  top: 30px;
  left: -50%;
  font-size: 4.8rem;
  font-weight: 700;
  letter-spacing: 4.8px;
  color: #dae7c1;
  z-index: 15;
}
@media screen and (max-width: 767px) {
  .fv__en-ttl.-service::after {
    top: 60px;
    font-size: 3rem;
    left: -30%;
  }
}
.fv__en-ttl.-price::after {
  content: "PRICING PLAN";
  position: absolute;
  display: inline-block;
  width: 355px;
  top: 30px;
  left: -50%;
  font-size: 4.8rem;
  font-weight: 700;
  letter-spacing: 4.8px;
  color: #dae7c1;
  z-index: 15;
}
@media screen and (max-width: 767px) {
  .fv__en-ttl.-price::after {
    top: 60px;
    font-size: 3rem;
    left: -30%;
  }
}
.fv__en-ttl.-faq::after {
  content: "FAQ";
  position: absolute;
  display: inline-block;
  width: 300px;
  top: 30px;
  left: 5%;
  font-size: 4.8rem;
  font-weight: 700;
  letter-spacing: 4.8px;
  color: #dae7c1;
  z-index: 15;
}
@media screen and (max-width: 767px) {
  .fv__en-ttl.-faq::after {
    top: 60px;
    font-size: 3rem;
    left: 5%;
  }
}
.fv__en-ttl.-fv::after {
  content: "FORM";
  position: absolute;
  display: inline-block;
  width: 300px;
  top: 30px;
  left: 5%;
  font-size: 4.8rem;
  font-weight: 700;
  letter-spacing: 4.8px;
  color: #dae7c1;
  z-index: 15;
}
@media screen and (max-width: 767px) {
  .fv__en-ttl.-fv::after {
    top: 60px;
    font-size: 3rem;
    left: 5%;
  }
}
.fv__icon {
  width: 105px;
  position: absolute;
  z-index: 11;
  top: 51%;
  right: 18.5%;
}

.profile {
  padding-bottom: 80px;
}
@media screen and (max-width: 767px) {
  .profile {
    padding-bottom: 45px;
  }
}
.profile__inner {
  display: flex;
  justify-content: space-around;
  margin-top: 60px;
}
@media screen and (max-width: 767px) {
  .profile__inner {
    margin-top: 45px;
  }
}
.profile__left {
  width: 25%;
  position: relative;
}
@media screen and (max-width: 767px) {
  .profile__left {
    display: none;
  }
}
.profile__right {
  width: 65%;
}
@media screen and (max-width: 767px) {
  .profile__right {
    width: 100%;
  }
}
.profile__heading {
  font-size: 2.4rem;
  line-height: 1.5;
}
@media screen and (max-width: 767px) {
  .profile__heading {
    font-size: 2rem;
    text-align: center;
  }
}
.profile__heading span {
  color: #5f9d7a;
  font-weight: 700;
}
.profile__txt {
  margin-top: 30px;
  font-size: 1.8rem;
  line-height: 1.5;
}
.profile__txt .sp-none {
  font-size: 1.8rem;
  line-height: 1.5;
}
@media screen and (max-width: 500px) {
  .profile__txt .sp-none {
    display: none;
  }
}
.profile__txt .strong {
  color: #5f9d7a;
  font-weight: 700;
}
@media screen and (max-width: 500px) {
  .profile__txt {
    font-size: 1.6rem;
  }
}

.sns {
  padding: 45px 0 80px;
  position: relative;
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .sns {
    padding: 45px 0;
  }
}
.sns__inner {
  position: relative;
  border: 2px solid #5f9d7a;
  padding: 70px 70px 110px;
  text-align: center;
  border-radius: 20px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .sns__inner {
    padding: 70px 30px 80px;
  }
}
@media screen and (max-width: 767px) {
  .sns__inner {
    padding: 70px 15px 80px;
  }
}
@media screen and (max-width: 500px) {
  .sns__inner {
    padding: 40px 15px 80px;
  }
}
.sns__ttl {
  position: absolute;
  top: -16px;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  padding: 0 20px;
  color: #5f9d7a;
  font-size: 2.8rem;
  background-color: #fcf9f1;
}
@media screen and (max-width: 767px) {
  .sns__ttl span {
    display: none;
  }
}
.sns__items {
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .sns__items {
    justify-content: space-between;
  }
}
.sns__item {
  width: 31%;
  display: block;
  padding: 10px;
  border-radius: 20px;
  background-color: #fff;
}
@media screen and (max-width: 767px) {
  .sns__item {
    width: 48%;
  }
}
@media screen and (max-width: 500px) {
  .sns__item {
    width: 100%;
  }
}
.sns__item:nth-child(1) {
  margin-right: 3%;
}
@media screen and (max-width: 767px) {
  .sns__item:nth-child(1) {
    margin-right: 0;
  }
}
@media screen and (max-width: 500px) {
  .sns__item:nth-child(1) {
    margin-top: 20px;
  }
}
.sns__item:nth-child(2) {
  margin-right: 3%;
}
@media screen and (max-width: 767px) {
  .sns__item:nth-child(2) {
    margin-right: 0;
  }
}
@media screen and (max-width: 500px) {
  .sns__item:nth-child(2) {
    margin-top: 20px;
  }
}
@media screen and (max-width: 767px) {
  .sns__item:nth-child(3) {
    margin-top: 20px;
  }
}
.sns__item:nth-child(4) {
  margin-right: 3%;
  margin-top: 40px;
}
@media screen and (max-width: 767px) {
  .sns__item:nth-child(4) {
    margin-right: 0;
    margin-top: 20px;
  }
}
.sns__item:nth-child(5) {
  margin-right: 3%;
  margin-top: 40px;
}
@media screen and (max-width: 767px) {
  .sns__item:nth-child(5) {
    margin-right: 0;
    margin-top: 20px;
  }
}
.sns__line {
  border: 3px dashed #5f9d7a;
  border-radius: 20px;
  padding: 20px 20px 0px;
}
.sns__item-ttl {
  font-size: 2.2rem;
}
.sns__lead {
  font-size: 1.6rem;
  line-height: 1.5;
  margin-top: 20px;
}
.sns__img {
  margin: 30px auto;
}
.sns__img.insta {
  width: 75px;
}
.sns__img.tiktok {
  width: 55px;
}
.sns__img.youtube {
  width: 75px;
}
.sns__img.twitter {
  width: 92px;
}
.sns__img.line {
  width: 75px;
}

.awards {
  padding: 120px 0 80px;
  overflow-y: hidden;
  background-color: #eff4e3;
}
.awards__inner {
  margin-top: 60px;
  width: 100%;
  position: relative;
  text-align: center;
}
.awards__inner::before {
  content: "";
  width: 200px;
  height: 368px;
  position: absolute;
  top: 0;
  left: 0;
  background: url("../img/pc/about/l-works-bg.png") no-repeat center center/cover;
}
@media screen and (max-width: 767px) {
  .awards__inner::before {
    display: none;
  }
}
.awards__inner::after {
  content: "";
  width: 200px;
  height: 368px;
  position: absolute;
  top: 0;
  right: 0;
  background: url("../img/pc/about/r-works-bg.png") no-repeat center center/cover;
}
@media screen and (max-width: 767px) {
  .awards__inner::after {
    display: none;
  }
}
.awards__item:last-child {
  margin-top: 60px;
}
@media screen and (max-width: 767px) {
  .awards__item:last-child {
    margin-top: 45px;
  }
}
.awards__heading {
  display: inline-block;
  padding-left: 40px;
  font-size: 1.6rem;
  position: relative;
}
.awards__heading::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0px;
  width: 29px;
  height: 20px;
  transform: translateY(-50%);
  background: url("../img/pc/about/okan.png") no-repeat center center/cover;
}
.awards__txt-inner {
  margin-top: 30px;
}
@media screen and (max-width: 767px) {
  .awards__txt-inner {
    margin-top: 20px;
  }
}
.awards__txt {
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 1.4;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .awards__txt {
    font-size: 2rem;
  }
}
@media screen and (max-width: 767px) {
  .awards__txt {
    font-size: 1.8rem;
  }
}
.awards__txt:not(:first-child) {
  margin-top: 14px;
}
@media screen and (max-width: 767px) {
  .awards__txt:not(:first-child) {
    margin-top: 10px;
  }
}
.awards__img-inner {
  display: inline-flex;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: -100px;
}
@media screen and (max-width: 767px) {
  .awards__img-inner {
    margin-bottom: -140px;
  }
}
.awards__img {
  width: 25%;
}
@media screen and (max-width: 767px) {
  .awards__img {
    width: 48%;
  }
}

.can {
  padding-bottom: 80px;
}
@media screen and (max-width: 767px) {
  .can {
    padding-bottom: 45px;
  }
}
@media screen and (max-width: 500px) {
  .can__br {
    display: block;
    margin-top: 5px;
  }
}
.can__inner {
  margin-top: 60px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .can__inner {
    margin-top: 45px;
  }
}
.can__heading {
  font-size: 2.4rem;
  display: inline-block;
  line-height: 1.5;
  background: url("../img/pc/service/ttl-bg.png") no-repeat center center/cover;
  letter-spacing: 2px;
}
@media screen and (max-width: 500px) {
  .can__heading {
    font-size: 1.8rem;
  }
}
.can__heading span {
  color: #5f9d7a;
  font-weight: 700;
}
.can__txt {
  width: 897px;
  max-width: 100%;
  margin: 30px auto;
  font-size: 1.8rem;
  line-height: 1.8;
  text-align: center;
}
@media screen and (max-width: 500px) {
  .can__txt {
    font-size: 1.6rem;
    width: 100%;
  }
}

.tab-items {
  margin-top: 30px;
}
.tab-items ul {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
  margin-bottom: -1px;
}

.tab-item {
  border: 1px solid #5f9d7a;
  border-radius: 12px 12px 0 0;
  background-color: #5f9d7a;
  color: #fff;
  width: 33.3%;
  height: 58px;
  text-align: center;
  line-height: 58px;
  font-size: 1.8rem;
  transition: height 0.3s;
  cursor: pointer;
}
@media screen and (max-width: 500px) {
  .tab-item {
    font-size: 1.6rem;
  }
}
.tab-item.active {
  height: 68px;
  line-height: 68px;
  background-color: #fff;
  color: #5f9d7a;
  font-size: 20px;
}
@media screen and (max-width: 500px) {
  .tab-item.active {
    font-size: 1.6rem;
  }
}

.tab-text {
  display: none;
  padding: 30px;
  background-color: #fff;
  border: 1px solid #5f9d7a;
}
.tab-text.active {
  display: block;
}

.tab-heading {
  font-size: 24px;
  font-weight: 700;
  color: #5f9d7a;
  line-height: 1.5;
}
@media screen and (max-width: 767px) {
  .tab-heading {
    font-size: 2.2rem;
  }
}

.tab-container {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .tab-container {
    display: block;
  }
}

.tab-img {
  width: 24%;
  border: 2px solid #333;
}
@media screen and (max-width: 767px) {
  .tab-img {
    width: 75%;
    margin: 0 auto;
  }
}

.tab-txt-inner {
  width: 70%;
}
@media screen and (max-width: 767px) {
  .tab-txt-inner {
    width: 100%;
    margin-top: 20px;
  }
}

.tab-txt-lead {
  font-size: 1.6rem;
  line-height: 1.5;
  padding: 30px 0 35px;
  border-bottom: 3px dashed #5f9d7a;
}
@media screen and (max-width: 500px) {
  .tab-txt-lead {
    padding: 15px 0 35px;
  }
}
.tab-txt-lead span {
  color: #e95383;
  font-weight: 700;
}

.tab-lists {
  margin-top: 30px;
}

.tab-lists-item-inner {
  display: flex;
}
@media screen and (max-width: 500px) {
  .tab-lists-item-inner {
    display: block;
  }
}
@media screen and (max-width: 500px) {
  .tab-lists-item-inner p {
    margin-top: 15px;
  }
}
.tab-lists-item-inner:last-child {
  margin-top: 15px;
}
@media screen and (max-width: 500px) {
  .tab-lists-item-inner:last-child {
    margin-top: 0;
  }
}
.tab-lists-item-inner p {
  font-size: 1.6rem;
}
.tab-lists-item-inner p:first-child {
  margin-right: 60px;
}
@media screen and (max-width: 767px) {
  .tab-lists-item-inner p:first-child {
    margin-right: 0px;
  }
}

.flow {
  padding: 60px 0 120px;
}
.flow__items {
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 500px) {
  .flow__items {
    display: block;
  }
}
.flow__item {
  background-color: #fff;
  padding: 60px 30px 30px;
  width: 32%;
  position: relative;
}
@media screen and (max-width: 500px) {
  .flow__item {
    width: 100%;
  }
  .flow__item:not(:first-child) {
    margin-top: 60px;
  }
}
.flow__step {
  position: absolute;
  top: -20px;
  left: 10px;
  font-size: 4.8rem;
  color: #b89d68;
  font-weight: 700;
}
.flow__step::after {
  content: "STEP";
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 24px;
  color: #b89d68;
  font-weight: 400;
}
.flow__img {
  width: 100%;
  margin: 0 auto;
}
.flow__img.flow01 {
  width: 80%;
}
.flow__img.flow03 {
  width: 66.25%;
}
.flow__heading {
  margin-top: 30px;
  font-size: 2.4rem;
  color: #5f9d7a;
  text-align: center;
  font-weight: 700;
}
.flow__lead {
  font-size: 1.6rem;
  line-height: 1.5;
  margin-top: 30px;
}

.other {
  background-color: #eff4e3;
  padding: 120px 0 80px;
}
.other__lead {
  margin-top: 60px;
  font-size: 1.8rem;
  line-height: 1.5;
}
@media screen and (max-width: 767px) {
  .other__lead {
    margin-top: 45px;
    font-size: 1.6rem;
  }
}
.other__logos {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.other__logo.ryomai {
  width: 11%;
}
@media screen and (max-width: 767px) {
  .other__logo.ryomai {
    width: 22%;
  }
}
.other__logo.batsu {
  width: 2%;
  margin: 0 30px;
}
@media screen and (max-width: 767px) {
  .other__logo.batsu {
    width: 4%;
    margin: 0 20px 0 35px;
  }
}
.other__logo.trave {
  width: 22%;
  width: 35%;
}
.other__items {
  margin-top: 60px;
}
.other__item {
  display: flex;
  justify-content: space-around;
}
@media screen and (max-width: 767px) {
  .other__item {
    display: block;
  }
}
.other__item:not(:first-child) {
  margin-top: 60px;
}
.other__item:nth-child(2n) {
  flex-direction: row-reverse;
}
@media screen and (max-width: 767px) {
  .other__item:nth-child(2n) {
    flex-direction: row;
  }
}
.other__txt {
  width: 60%;
}
@media screen and (max-width: 767px) {
  .other__txt {
    width: 100%;
  }
}
.other__heading {
  color: #5f9d7a;
  font-size: 2.2rem;
}
@media screen and (max-width: 767px) {
  .other__heading {
    font-size: 2rem;
  }
}
.other__heading .number {
  font-size: 2.4rem;
  display: inline-block;
  padding-bottom: 5px;
  border-bottom: 4px solid #5f9d7a;
  position: relative;
}
@media screen and (max-width: 767px) {
  .other__heading .number {
    font-size: 2.2rem;
  }
}
.other__heading .number::after {
  font-size: 4.8rem;
  font-weight: 700;
  position: absolute;
  top: -4px;
  right: -40px;
}
@media screen and (max-width: 767px) {
  .other__heading .number::after {
    font-size: 4.6rem;
    right: -38px;
  }
}
.other__heading .txt1::after {
  content: "1";
}
.other__heading .txt2::after {
  content: "2";
}
.other__heading .strong {
  margin-top: 22px;
  display: block;
  font-weight: 700;
}
@media screen and (max-width: 767px) {
  .other__heading .strong {
    margin-top: 25px;
  }
}
.other__item-lead {
  font-size: 1.8rem;
  margin-top: 20px;
  line-height: 1.5;
}
@media screen and (max-width: 500px) {
  .other__item-lead {
    font-size: 1.6rem;
  }
}
.other__img {
  width: 25%;
}
@media screen and (max-width: 767px) {
  .other__img {
    width: 60%;
    margin: 30px auto;
  }
}
.other__btn {
  margin-top: 60px;
  text-align: center;
}

.contact {
  padding: 30px 0 80px;
}
.contact.-confirm {
  padding: 150px 0 80px;
}
.contact.-complete {
  padding: 150px 0 80px;
}
.contact__ttl {
  text-align: center;
  font-size: 4rem;
  font-weight: 700;
}
.contact__ttl.-complete {
  margin-top: 60px;
}
.contact__ttl.-page404 {
  margin-top: 60px;
  font-size: 6rem;
}
.contact__caution {
  font-size: 1.6rem;
  line-height: 1.5;
  text-align: center;
  margin-top: 45px;
}
.contact__circles {
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact__circles.-confirm {
  margin-top: 120px;
}
.contact__circle {
  display: inline-flex;
  width: 160px;
  height: 160px;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  color: #dae7c1;
  border: 4px solid #dae7c1;
  border-radius: 50%;
}
@media screen and (max-width: 767px) {
  .contact__circle {
    width: 115px;
    height: 115px;
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 500px) {
  .contact__circle {
    width: 90px;
    height: 90px;
    font-size: 2rem;
  }
}
.contact__circle.active {
  color: #fff;
  background-color: #5f9d7a;
  border: none;
}
.contact__bar {
  display: inline-block;
  width: 100px;
  height: 4px;
  background-color: #dae7c1;
}
@media screen and (max-width: 767px) {
  .contact__bar {
    width: 60px;
  }
}
@media screen and (max-width: 767px) {
  .contact__bar {
    width: 40px;
  }
}
.contact__bar.active {
  background-color: #5f9d7a;
}
.contact__lead {
  font-size: 1.8rem;
  text-align: center;
  line-height: 1.5;
  margin-top: 60px;
}
@media screen and (max-width: 500px) {
  .contact__lead {
    font-size: 1.6rem;
  }
}
.contact__form {
  margin-top: 60px;
}
.contact__items {
  width: 850px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.contact__item {
  display: block;
}
.contact__item:nth-of-type(n + 2) {
  margin-top: 40px;
}
.contact__label {
  display: block;
  position: relative;
}
.contact__label label {
  display: inline-block;
  font-size: 1.8rem;
  font-weight: 700;
  padding: 14px 0;
}
.contact__label label span {
  position: relative;
  font-weight: 700;
}
.contact__label label span::after {
  content: "必須";
  position: absolute;
  display: inline-block;
  top: 50%;
  right: -60px;
  transform: translateY(-50%);
  padding: 5px 10px;
  background-color: #ff7f16;
  color: #fff;
  font-size: 12px;
  line-height: 1;
}
.contact__input {
  display: block;
  margin-top: 5px;
  font-size: 1.6rem;
}
.contact__input [type=text], .contact__input [type=email], .contact__input [type=tel] {
  width: 100%;
  height: 45px;
  border: none;
  box-shadow: none;
  border-radius: 2px;
  background: #fff;
  padding: 15px 16px;
  appearance: none;
  color: inherit;
  font-family: inherit;
  font-size: 1.6rem;
  border: none;
  line-height: 1;
  margin: 0;
}
.contact__input [type=text]:focus, .contact__input [type=email]:focus, .contact__input [type=tel]:focus {
  box-shadow: none;
  outline: 2px solid rgb(0, 103, 255);
}
.contact__input [type=text]::placeholder, .contact__input [type=email]::placeholder, .contact__input [type=tel]::placeholder {
  color: #cccccc;
}
.contact__input textarea {
  width: 100%;
  height: 122px;
  border: none;
  box-shadow: none;
  border-radius: 2px;
  background: #fff;
  padding: 15px 16px;
  appearance: none;
  color: inherit;
  font-family: inherit;
  font-size: 1.6rem;
  resize: vertical;
  line-height: 1.4;
}
.contact__input textarea:focus {
  box-shadow: none;
  outline: 2px solid rgb(0, 103, 255);
}
.contact__select {
  width: 100%;
  box-shadow: none;
  border: none;
  padding: 15px 16px;
  margin: 0;
  appearance: none;
  border-radius: 0;
  color: #333;
  font-family: inherit;
  font-size: 1.6rem;
  line-height: 1;
  width: 100%;
  background: #fff url("../img/pc/contact/contact-arrow.png") no-repeat right 12px center/12px 8px;
}
.contact__select:focus-visible {
  outline: 2px solid rgb(0, 103, 255);
  box-shadow: none;
}
.contact__btn-inner {
  text-align: center;
  margin-top: 48px;
}
.contact__submit-inner {
  text-align: center;
  margin-top: 48px;
  display: flex;
  justify-content: center;
}
.contact__submit-inner [type=submit]:focus-visible {
  outline: 2px solid rgb(0, 103, 255);
}
.contact__submit-inner.-complete {
  margin-top: 120px;
}
.contact__back-btn {
  background-color: #dae7c1;
  color: #fff;
  border: 2px solid #dae7c1;
  margin-right: 45px;
}

#loader-bg {
  opacity: 1;
  visibility: visible;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: #fcf9f1;
  z-index: 1000;
}
#loader-bg.is-active {
  opacity: 0;
  visibility: hidden;
}

#loader {
  width: 100%;
  height: 100%;
}

#logo-inner {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
}

#loader-logo {
  position: absolute;
  top: 0;
  left: 100px;
  width: 200px;
  transition: left 1s;
  animation: logo 1.1s ease-in-out infinite;
}

#load-txt {
  position: relative;
  top: 51%;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 2.2rem;
}

@keyframes logo {
  0% {
    left: 100px;
  }
  100% {
    left: -100px;
  }
}
.price {
  padding: 0 0 60px;
}
.price__lead {
  font-size: 1.6rem;
  text-align: center;
  margin-top: 28px;
  line-height: 1.5;
}
.price__items {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .price__items {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .price__items {
    display: block;
  }
}
.price__item {
  flex: 0 1 280px;
  border: 3px solid #5f9d7a;
  background-color: #fff;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .price__item {
    width: 80%;
    margin: 0 auto;
  }
}
.price__item.-option .price__bottom {
  padding: 0;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .price__item.-option .price__bottom {
    width: 80%;
    margin: 0 auto;
    padding: 100px 0;
    display: block;
    text-align: center;
  }
}
@media screen and (max-width: 767px) {
  .price__item.-option .price__bottom {
    width: 80%;
    margin: 0 auto;
    padding: 80px 0;
    display: block;
    text-align: center;
  }
}
.price__head {
  background-color: #5f9d7a;
  padding: 30px 0;
}
.price__heading {
  font-size: 2.4rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
}
.price__bottom {
  padding: 60px 0 70px;
  text-align: center;
  font-size: 3rem;
  font-weight: 700;
}
@media screen and (max-width: 767px) {
  .price__bottom {
    padding: 45px 0;
  }
}
.price__bottom .strong {
  display: inline-block;
  background: linear-gradient(180deg, transparent 0%, transparent 60%, #fef97f 60%, #fef97f 100%);
}
.price__bottom .strong span {
  font-size: 2rem;
  padding-top: 10px;
}
.price__bars {
  margin: 0 20px;
  display: flex;
  align-items: center;
  color: #5f9d7a;
  font-size: 3.4rem;
  font-weight: 700;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .price__bars {
    justify-content: center;
    margin: 20px 0;
  }
}
@media screen and (max-width: 767px) {
  .price__bars {
    justify-content: center;
    margin: 20px 0;
  }
}
.price__bar {
  width: 25px;
  height: 5px;
  background-color: #5f9d7a;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.price__bar:last-child {
  transform: translateY(-50%) rotate(90deg);
}

.u-sp-block {
  display: none;
}
@media screen and (max-width: 500px) {
  .u-sp-block {
    display: block;
  }
}