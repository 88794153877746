@use "global" as g;

.faq{
    position: relative;
    z-index: 3;
    top: -60px;
    background-color: g.$bg_color01;
    padding: 120px 0 80px;

    @include g.mq(sp){
        padding: 80px 0 60px;
    }

    &.-padding{
        padding: 60px 0 0px;

        @include g.mq(sp){
            padding: 45px 0 0px;
        }
    }

    &__items{
        margin-top: 80px;

        @include g.mq(sp){
            margin-top: 45px;
        }

        &.-margin{
            margin-top: 0;
        }
    }

    &__item{
        border-bottom: 3px dashed g.$gr_color;
    }

    &__q{
        padding: 30px 60px;
        position: relative;
        font-size: g.$sm_fsz;
        line-height: 1.5;
        cursor: pointer;

        @include g.mq(sp){
            padding: 30px 40px 15px 60px;
            font-size: g.$xs_fsz;
        }

        &::before{
        content: "Q";
        position: absolute;
        top: 27px;
        left: 20px;
        font-size: g.$xs_fsz;
        border: 1px solid g.$gr_color;
        border-radius: 50%;
        padding: 2px 9px 5px;
        color: g.$gr_color;
        line-height: 1.5;
        }
    }

    &__icon{
        width: 14px;
        height: 14px;
        position: absolute;
        top: 50%;
        right: 14px;
        transform: translateY(-50%);

        @include g.mq(xs){
            top: 58%;
        }

        &.open{
        .faq__bar2{
            transform: rotate(90deg);
        }
        }
    }

    &__bar1{  // 横の棒
        width: 14px;
        height: 2px;
        background-color: g.$gr_color;
        border-radius: 20px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }

    &__bar2{  // 縦の棒
        width: 2px;
        height: 14px;
        background-color: g.$gr_color;
        border-radius: 20px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        transition: all 0.3s ease 0s;  // 縦の棒を消す時のアニメーション
    }

    &__a{
        font-size: g.$sm_fsz;
        line-height: 1.5;
        padding: 15px 60px 30px;
        position: relative;
        letter-spacing: 1px;
        display: none;
        
        @include g.mq(xs){
            font-size: g.$xs_fsz;
        }

        &::before{
            content: "A";
            position: absolute;
            top: 16px;
            left: 20px;
            font-size: g.$xs_fsz;
            border: 1px solid #bea574;
            border-radius: 50%;
            padding: 2px 9px;
            color: #bea574;
            line-height: 1.5;
            }
    }

    &__content{
        position: relative;
    }

    &__btn-inner{
        margin-top: 60px;
        text-align: center;
    }
}