@use "global" as g;

.sns{
    padding: 45px 0 80px;
    position: relative;
    z-index: 1;

    @include g.mq(sp){
        padding: 45px 0;
    }

    &__inner{
        position: relative;
        border: 2px solid g.$gr_color;
        padding: 70px 70px 110px;
        text-align: center;
        border-radius: 20px;

        @include g.mq(tab){
            padding: 70px 30px 80px;
        }

        @include g.mq(sp){
            padding: 70px 15px 80px;
        }

        @include g.mq(xs){
            padding: 40px 15px 80px;
        }
    }

    &__ttl{
        position: absolute;
        top: -16px;
        left: 50%;
        transform: translateX(-50%);
        display: inline-block;
        padding: 0 20px;
        color: g.$gr_color;
        font-size: 2.8rem;
        background-color: g.$bg_color01;

        span{
            @include g.mq(sp){
                display: none;
            }
        }
    }

    &__items{
        flex-wrap: wrap;
        display: flex;
        justify-content: center;

        @include g.mq(sp){
            justify-content: space-between;
        }
    }

    &__item{
        width: 31%;
        display: block;
        padding: 10px;
        border-radius: 20px;
        background-color: g.$wh_color;

        @include g.mq(sp){
            width: 48%;
        }

        @include g.mq(xs){
            width: 100%;
        }

        &:nth-child(1){
            margin-right: 3%;

            @include g.mq(sp){
                margin-right: 0;
            }

            @include g.mq(xs){
                margin-top: 20px;
            }
        }

        &:nth-child(2){
            margin-right: 3%;

            @include g.mq(sp){
                margin-right: 0;
            }

            @include g.mq(xs){
                margin-top: 20px;
            }
        }

        &:nth-child(3){
            @include g.mq(sp){
                margin-top: 20px;
            }
        }

        &:nth-child(4){
            margin-right: 3%;
            margin-top: 40px;

            @include g.mq(sp){
                margin-right: 0;
                margin-top: 20px;
            }
        }

        &:nth-child(5){
            margin-right: 3%;
            margin-top: 40px;

            @include g.mq(sp){
                margin-right: 0;
                margin-top: 20px;
            }
        }
    }

    &__line{
        border: 3px dashed g.$gr_color;
        border-radius: 20px;
        padding: 20px 20px 0px;
    }

    &__item-ttl{
        font-size: g.$md_fsz;
    }

    &__lead{
        font-size: g.$xs_fsz;
        line-height: 1.5;
        margin-top: 20px;
    }

    &__img{
        margin: 30px auto;

        &.insta{
            width: 75px;
        }

        &.tiktok{
            width: 55px;
        }

        &.youtube{
            width: 75px;
        }

        &.twitter{
            width: 92px;
        }

        &.line{
            width: 75px;
        }
    }
}