@use "global" as g;

.fv{
    width: 100%;
    position: relative;
    overflow-x: hidden;
    overflow-y: visible;
    padding: 80px 0 120px;

    @include g.mq(sp){
        padding: 80px 0;
    }

    .pc{
        display: block;

        @include g.mq(tab){
            display: none;
        }

        @include g.mq(sp){
            display: none;
        }
    }

    .sp{
        display: none;

        @include g.mq(tab){
            display: block;
        }

        @include g.mq(sp){
            display: block;
        }
    }

    &__img{
        width: 75%;
        background: url('') no-repeat center center / cover;
        position: relative;
        z-index: 10;
    }

    &__ttl{
        font-size: 2.4rem;
        font-weight: g.$fw_bold;
        letter-spacing: 2.4px;
        writing-mode: vertical-rl;
        position: absolute;
        top: -50px;
        right: -30px;
        z-index: 1;
        width: 480px;
        height: 480px;
        background-color: g.$bg_color02;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 140px;
        padding-left: 40px;
        padding-bottom: 60px;

        @include g.mq(tab){
            justify-content: right;
        }

        @include g.mq(sp){
            justify-content: right;
            width: 420px;
            height: 420px;
            font-size: 2.0rem;
        }

        @include g.mq(xs){
            font-size: 2rem;
            right: -80px;
            width: 370px;
            height: 370px;
            padding-bottom: 0px;
        }

        span{
            display: block;
            font-size: g.$xs_fsz;
            color: g.$gr_color;
            margin-left: 14px;
            letter-spacing: 1.6px;

            @include g.mq(tab){
                margin-right: 100px;
            }

            @include g.mq(sp){
                margin-right: 100px;
                margin-left: 7px;
            }
        }
    }

    &__en-ttl{
        position: absolute;
        top: 60%;
        right: 15%;
        z-index: 13;
        width: 150px;
        height: 150px;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.5);

        @include g.mq(sp){
            width: 120px;
            height: 120px;
            right: 20%;
        }


        @include g.mq(xs){
            display: none;
        }


        &.-about{
            &::after{
                content: "ABOUT US";
                position: absolute;
                display: inline-block;
                width: 300px;
                top: 30px;
                left: -50%;
                font-size: 4.8rem;
                font-weight: g.$fw_bold;
                letter-spacing: 4.8px;
                color: #dae7c1;
                z-index: 15;
    
                @include g.mq(sp){
                    top: 60px;
                    font-size: 3rem;
                    left: -30%;
                }
            }
        }

        &.-service{
            &::after{
                content: "WHAT WE DO";
                position: absolute;
                display: inline-block;
                width: 355px;
                top: 30px;
                left: -50%;
                font-size: 4.8rem;
                font-weight: g.$fw_bold;
                letter-spacing: 4.8px;
                color: #dae7c1;
                z-index: 15;
    
                @include g.mq(sp){
                    top: 60px;
                    font-size: 3rem;
                    left: -30%;
                }
            }
        }

        &.-price{
            &::after{
                content: "PRICING PLAN";
                position: absolute;
                display: inline-block;
                width: 355px;
                top: 30px;
                left: -50%;
                font-size: 4.8rem;
                font-weight: g.$fw_bold;
                letter-spacing: 4.8px;
                color: #dae7c1;
                z-index: 15;
    
                @include g.mq(sp){
                    top: 60px;
                    font-size: 3rem;
                    left: -30%;
                }
            }
        }

        &.-faq{
            &::after{
                content: "FAQ";
                position: absolute;
                display: inline-block;
                width: 300px;
                top: 30px;
                left: 5%;
                font-size: 4.8rem;
                font-weight: g.$fw_bold;
                letter-spacing: 4.8px;
                color: #dae7c1;
                z-index: 15;
    
                @include g.mq(sp){
                    top: 60px;
                    font-size: 3rem;
                    left: 5%;
                }
            }
        }

        &.-fv{
            &::after{
                content: "FORM";
                position: absolute;
                display: inline-block;
                width: 300px;
                top: 30px;
                left: 5%;
                font-size: 4.8rem;
                font-weight: g.$fw_bold;
                letter-spacing: 4.8px;
                color: #dae7c1;
                z-index: 15;
    
                @include g.mq(sp){
                    top: 60px;
                    font-size: 3rem;
                    left: 5%;
                }
            }
        }
    }

    &__icon{
        width: 105px;
        position: absolute;
        z-index: 11;
        top: 51%;
        right: 18.5%;
    }
}