@use "global" as g;

.footer{
    padding: 30px 0;

    &__items{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__item{
        width: 40px;
        margin-left: 30px;

        &:first-child{
            margin-left: 0;
        }
    }

    &__copyright{
        display: block;
        text-align: center;
        font-size: 1.2rem;
        margin-top: 15px;
    }
}