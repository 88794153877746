@use "global" as g;

.example{
    // background-color: g.$bg_color02;
    background: url('../img/pc/top/gr-bg.png') no-repeat center center / cover;
    display: flex;
    justify-content: center;
    padding: 170px 0 80px;
    position: relative;
    z-index: 2;
    top: 10px;

    @include g.mq(sp){
        background: url('../img/sp/top/gr-bg.png') no-repeat center center / cover;
        padding: 140px 0 80px;
    }

    &__inner{
        width: 57%;
        margin: 0 auto;
        position: relative;
        border: 3px dashed g.$gr_color;
        border-radius: 20px;
        text-align: center;
        padding: 60px 0 150px;
        display: inline-flex;
        flex-direction: column;
        align-items: center;

        @include g.mq(tab){
            width: 100%;
            margin: 0 60px;
            padding: 30px 15px 100px;
        }

        @include g.mq(sp){
            width: 100%;
            margin: 0 20px;
            padding: 30px 15px 100px;
        }

        &::before{
            content: "";
            position: absolute;
            top: -75px;
            left: -30px;
            width: 114px;
            height: 97px;
            display: inline-block;
            background: url('../img/icon/illustration01.png') no-repeat center center / cover;

            @include g.mq(xs){
                display: none;
            }
        }

        &::after{
            content: "";
            position: absolute;
            bottom: 0px;
            right: -30px;
            width: 114px;
            height: 97px;
            display: inline-block;
            background: url('../img/icon/illustration02.png') no-repeat center center / cover;

            @include g.mq(xs){
                right: -16px;
            }
        }
    }

    &__ttl{
        position: relative;
        top: -90px;
        background-color: g.$bg_color02;
        padding: 0 20px;
        display: inline-block;

        @include g.mq(tab){
            top: -60px;
        }

        @include g.mq(sp){
            top: -60px;
        }
    }

    &__items{
        justify-content: center;
        width: 160%;
        max-width: 1180px;
        margin: 0 calc(50% - 50vw);
        padding: 0 40px;

        @include g.mq(tab){
            width: 100%;
            padding: 0 25px;
        }

        @include g.mq(sp){
            width: 100%;
            padding: 0;
            margin: -45px calc(50% - 50vw);
        }
    }

    &__items-inner{
        display: flex;
        justify-content: space-between;

        @include g.mq(tab){
            display: block;
        }

        @include g.mq(sp){
            display: block;
        }
    }

    &__item{
        width: 30%;
        background-color: g.$wh_color;
        padding: 60px 34px 70px;
        border-radius: 20px;
        text-align: left;

        &:nth-of-type(2){
            position: relative;
            top: 60px;

            @include g.mq(tab){
                top: 0;
            }

            @include g.mq(sp){
                top: 0;
            }
        }

        @include g.mq(tab){
            width: 100%;
            margin-top: 30px;
            padding: 30px 15px 45px;
        }

        @include g.mq(sp){
            width: 100%;
            margin-top: 30px;
            padding: 50px 30px 45px;
        }
    }

    &__item-ttl{
        font-size: g.$md_fsz;
        font-weight: g.$fw_bold;
        color: g.$gr_color;
        line-height: 1.5;
    }

    &__company{
        font-size: g.$md_fsz;
        margin-top: 30px;
    }

    &__lead{
        font-size: g.$xs_fsz;
        line-height: 1.5;
        margin-top: 15px;

        span{
            color: #e95383;
            font-weight: g.$fw_bold;
        }
    }
}