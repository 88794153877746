@use "global" as g;

.c-ttl{
    font-size: g.$lg_fsz;
    letter-spacing: 3.6px;
    text-align: center;

    @include g.mq(sp){
        font-size: 3rem;
    }

    .br{
        display: block;
        margin-top: 6px;
    }


    &.about{
        border-bottom: 2px dashed g.$gr_color;
        padding-bottom: 10px;
        display: inline-block;
    }

    span{
        color: g.$gr_color;
        font-weight: g.$fw_bold;
        font-size: 4rem;
        -webkit-text-stroke: 0.6px g.$wh_color;

        @include g.mq(sp){
            font-size: 3.4rem;
        }
    }
}

.c-sub-ttl{
    font-size: 2.4rem;
    font-weight: g.$fw_bold;
    text-align: center;
    color: g.$gr_color;
}

