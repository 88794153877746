@use "global" as g;

.price{
    padding: 0 0 60px;

    &__lead{
        font-size: g.$xs_fsz;
        text-align: center;
        margin-top: 28px;
        line-height: 1.5;
    }

    &__items{
        margin-top: 40px;
        display: flex;
        justify-content: center;

        @include g.mq(tab){
            display: block;
        }

        @include g.mq(sp){
            display: block;
        }
    }

    &__item{
        flex: 0 1 280px;
        border: 3px solid g.$gr_color;
        background-color: g.$wh_color;

        @include g.mq(tab){
            width: 80%;
            margin: 0 auto;
        }

        &.-option{
            .price__bottom{
                padding: 0;
                display: flex;
                align-items: center;
                height: 100%;
                justify-content: center;

                @include g.mq(tab){
                    width: 80%;
                    margin: 0 auto;
                    padding: 100px 0;
                    display: block;
                    text-align: center;
                }

                @include g.mq(sp){
                    width: 80%;
                    margin: 0 auto;
                    padding: 80px 0;
                    display: block;
                    text-align: center;
                }
            }
        }
    }

    &__head{
        background-color: g.$gr_color;
        padding: 30px 0;
    }

    &__heading{
        font-size: 2.4rem;
        font-weight: g.$fw_bold;
        color: g.$wh_color;
        text-align: center;
    }

    &__bottom{
        padding: 60px 0 70px;
        text-align: center;
        font-size: 3rem;
        font-weight: g.$fw_bold;

        @include g.mq(sp){
            padding: 45px 0;
        }
        


        .strong{
            display: inline-block;
            background: linear-gradient(180deg, transparent 0%, transparent 60%, #fef97f 60%, #fef97f 100%);

            span{
                font-size: 2rem;
                padding-top: 10px;
            }
        }
    }

    &__bars{
        margin: 0 20px;
        display: flex;
        align-items: center;
        color: g.$gr_color;
        font-size: 3.4rem;
        font-weight: g.$fw_bold;

        @include g.mq(tab){
            justify-content: center;
            margin: 20px 0;
        }

        @include g.mq(sp){
            justify-content: center;
            margin: 20px 0;
        }
    }

    &__bar{
        width: 25px;
        height: 5px;
        background-color: g.$gr_color;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);

        &:last-child{
            transform: translateY(-50%) rotate(90deg);
        }
    }
}