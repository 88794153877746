@use "global" as g;

.top-about{
    text-align: center;
    padding: 120px 0 80px;

    @include g.mq(tab){
        padding: 120px 0 180px;
    }

    &__item{
        margin-top: 150px;
        position: relative;

        @include g.mq(sp){
            margin-top: 45px;
        }

        &:nth-child(4){
            margin-top: 0;

            .top-about__txt{
                position: relative;
                margin-left: auto;
                z-index: 1;
                width: 41.8%;
                background-color: g.$wh_color;
                border-radius: 20px;
                box-shadow: 4px 3px #ddcfb2;
                padding: 8px;

                @include g.mq(tab){
                    width: 60%;
                }
        
                @include g.mq(sp){
                    width: 100%;
                }
            }

            .top-about__img{
                width: 65%;
                position: absolute;
                top: -90px;
                left: 0;

                @include g.mq(tab){
                    width: 45%;
                    top: 60px;
                }

                @include g.mq(sp){
                    display: none;
                }
            }
        }
    }

    &__txt{
        position: relative;
        z-index: 1;
        width: 41.8%;
        background-color: g.$wh_color;
        border-radius: 20px;
        box-shadow: 4px 3px #ddcfb2;
        padding: 8px;

        @include g.mq(tab){
            width: 60%;
        }

        @include g.mq(sp){
            width: 100%;
        }
    }

    &__line{
        border: 3px dashed g.$gr_color;
        border-radius: 20px;
        padding: 30px 60px;

        @include g.mq(sp){
            padding: 30px;
        }
    }

    &__txt-ttl{
        text-align: center;
    }

    &__sub-ttl{
        display: inline-block;
        font-size: g.$sm_fsz;
        position: relative;

        &::before{
            content: "";
            display: inline-block;
            position: absolute;
            bottom: 8px;
            left: -25px;
            transform: rotate(55deg);
            width: 18px;
            height: 1px;
            background-color: g.$bk_color;
        }

        &::after{
            content: "";
            display: inline-block;
            position: absolute;
            bottom: 8px;
            right: -25px;
            transform: rotate(125deg);
            width: 18px;
            height: 1px;
            background-color: g.$bk_color;
        }
    }

    &__ttl-strong{
        display: block;
        font-size: g.$md_fsz;
        font-weight: g.$fw_bold;
        color: g.$gr_color;
        margin-top: 10px;
    }

    &__body{
        margin-top: 28px;
    }

    &__lead{
        font-size: g.$xs_fsz;
        letter-spacing: 1.6px;
        line-height: 1.5;
        text-align-last: left;
    }

    &__btn-inner{
        margin-top: 35px;
        text-align: center;
    }

    &__img{
        width: 65%;
        position: absolute;
        top: -90px;
        right: 0;

        @include g.mq(tab){
            width: 45%;
        }

        @include g.mq(sp){
            display: none;
        }

        .pc{
            display: block;

            @include g.mq(tab){
                display: none;
            }
        }

        .sp{
            display: none;

            @include g.mq(tab){
                display: block;
            }

            @include g.mq(sp){
                display: none;
            }
        }
    }

    &__bg{
        width: 58%;
        margin: 0 auto;
    }
}