@use "global" as g;

.c-btn{
    display: inline-block;
    padding: 14px 60px;
    font-size: g.$xs_fsz;
    color: g.$wh_color;
    background-color: g.$gr_color;
    border-radius: 20px;
    border: 2px solid g.$gr_color;
    transition: background 0.3s ease 0s;

    &:hover{
        color: g.$bk_color;
        background-color: #fff;
    }
}