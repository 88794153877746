@use "global" as g;

.other{
    background-color: g.$bg_color02;
    padding: 120px 0 80px;

    &__lead{
        margin-top: 60px;
        font-size: g.$sm_fsz;
        line-height: 1.5;

        @include g.mq(sp){
            margin-top: 45px;
            font-size: g.$xs_fsz;
        }
    }

    &__logos{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 40px;
    }

    &__logo{

        &.ryomai{
            width: 11%;

            @include g.mq(sp){
                width: 22%;
            }
        }

        &.batsu{
            width: 2%;
            margin: 0 30px;

            @include g.mq(sp){
                width: 4%;
                margin: 0 20px 0 35px;
            }
        }

        &.trave{
            width: 22%;

            width: 35%;
        }
    }

    &__items{
        margin-top: 60px;
    }

    &__item{
        display: flex;
        justify-content: space-around;

        @include g.mq(sp){
            display: block;
        }

        &:not(:first-child){
            margin-top: 60px;
        }

        &:nth-child(2n){
            flex-direction: row-reverse;

            @include g.mq(sp){
                flex-direction: row;
            }
        }
    }

    &__txt{
        width: 60%;

        @include g.mq(sp){
            width: 100%;
        }
    }

    &__heading{
        color: g.$gr_color;
        font-size: g.$md_fsz;

        @include g.mq(sp){
            font-size: 2.0rem;
        }

        .number{
            font-size: 2.4rem;
            display: inline-block;
            padding-bottom: 5px;
            border-bottom: 4px solid g.$gr_color;
            position: relative;

            @include g.mq(sp){
                font-size: 2.2rem;
            }

            &::after{
                font-size: 4.8rem;
                font-weight: g.$fw_bold;
                position: absolute;
                top: -4px;
                right: -40px;

                @include g.mq(sp){
                    font-size: 4.6rem;
                    right: -38px;
                }
            }
        }

        .txt1{
            &::after{
                content: "1";
            }
        }

        .txt2{
            &::after{
                content: "2";
            }
        }

        .strong{
            margin-top: 22px;
            display: block;
            font-weight: g.$fw_bold;

            @include g.mq(sp){
                margin-top: 25px;
            }
        }
    }

    &__item-lead{
        font-size: g.$sm_fsz;
        margin-top: 20px;
        line-height: 1.5;

        @include g.mq(xs){
            font-size: g.$xs_fsz;
        }
    }

    &__img{
        width: 25%;

        @include g.mq(sp){
            width: 60%;
            margin: 30px auto;
        }
    }

    &__btn{
        margin-top: 60px;
        text-align: center;
    }
}