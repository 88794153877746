@use "global" as g;

html, body{
    font-size: 62.5%;
    }
    
body{
    font-family: g.$font;
    color: g.$bk_color;
    background-color: g.$bg_color01;
    position: relative;
    min-height: 100vh;
    padding-bottom: 144px;
    box-sizing: border-box;
}

li{
    list-style: none;
}

img{
    width: 100%;
    height: auto;
}

a{
    text-decoration: none;
    color: g.$bk_color;
    cursor: pointer;
}

a:hover{
    opacity: 0.7;
}

button{
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;
}

