@use "global" as g;

.flow{
    padding: 60px 0 120px;

    &__items{
        margin-top: 80px;
        display: flex;
        justify-content: space-between;

        @include g.mq(xs){
            display: block;
        }
    }

    &__item{
        background-color: g.$wh_color;
        padding: 60px 30px 30px;
        width: 32%;
        position: relative;

        @include g.mq(xs){
            width: 100%;

            &:not(:first-child){
                margin-top: 60px;
            }
        }
    }

    &__step{
        position: absolute;
        top: -20px;
        left: 10px;
        font-size: 4.8rem;
        color: #b89d68;
        font-weight: g.$fw_bold;

        &::after{
            content: "STEP";
            position: absolute;
            top: -30px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 24px;
            color: #b89d68;
            font-weight: g.$fw_regular;
        }
    }

    &__img{
        width: 100%;
        margin: 0 auto;

        &.flow01{
            width: 80%;
        }

        &.flow03{
            width: 66.25%;
        }
    }

    &__heading{
        margin-top: 30px;
        font-size: 2.4rem;
        color: g.$gr_color;
        text-align: center;
        font-weight: g.$fw_bold;
    }

    &__lead{
        font-size: g.$xs_fsz;
        line-height: 1.5;
        margin-top: 30px;
    }
}