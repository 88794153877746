@use "global" as g;

.l-container{
    width: 100%;
    max-width: 1180px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 40px;

    @include g.mq(sp){
        padding: 0 20px;
    }
}