$breakpoints: (
  'xs': 'screen and (max-width: 500px)',
  'sp': 'screen and (max-width: 767px)',
  'tab': 'screen and (min-width: 768px) and (max-width: 1024px)',
  'pc': 'screen and (min-width: 1025px)'
) !default;

@mixin mq($breakpoint: pc) {
  @media #{map-get($breakpoints, $breakpoint)}{
    @content;
  }
}



