@use "global" as g;

.cta{
    padding: 120px 0 100px;

    @include g.mq(sp){
        padding: 80px 0 60px;
    }

    &__inner{
        text-align: center;
    }

    &__lead{
        font-size: g.$xs_fsz;
        line-height: 1.5;
        margin-top: 60px;

        @include g.mq(sp){
            margin-top: 45px;
        }
    }

    &__strong{
        font-size: g.$xs_fsz;
        line-height: 1.5;
        margin-top: 50px;
        position: relative;
        display: inline-block;

        @include g.mq(sp){
            margin-top: 40px;
        }

        &::before{
            content: "";
            position: absolute;
            top: 10px;
            left: -50px;
            width: 30px;
            height: 1px;
            background-color: g.$gr_color;
            transform: rotate(50deg);

            @include g.mq(sp){
                left: -30px;
            }
        }

        &::after{
            content: "";
            position: absolute;
            top: 10px;
            right: -50px;
            width: 30px;
            height: 1px;
            background-color: g.$gr_color;
            transform: rotate(130deg);

            @include g.mq(sp){
                right: -30px;
            }
        }
    }

    &__btn-inner{
        margin-top: 10px;
    }
}