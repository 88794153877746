@use "global" as g;

@keyframes anime {
    0% {
        opacity: 0;
    }
    33% {
        opacity: 1;
    }
    66% {
        opacity: 0;
        transform: scale(1.2) ;
        z-index:9;
    }

    100% { opacity: 0 }
}


.mv{
    height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;

    &__bg{
        width: 100%;
        height: 100vh;
        z-index: 10;
        opacity: 0;
        background: url('../img/pc/top/mv1.jpg') no-repeat center center / cover;
        position: absolute;
        left: 0;
        top: 0;
        animation: anime 24s 0s infinite;

        @include g.mq(sp){
            background: url('../img/sp/top/mv1.png') no-repeat center center / cover;
        }
    
        &:nth-child(2){
            animation-delay: 8s;
            background: url('../img/pc/top/mv2.jpg') no-repeat center center / cover;

            @include g.mq(sp){
                background: url('../img/sp/top/mv2.png') no-repeat center center / cover;
            }
        }
    
        &:nth-child(3){
            animation-delay: 16s;
            background: url('../img/pc/top/mv3.jpg') no-repeat center center / cover;

            @include g.mq(sp){
                background: url('../img/sp/top/mv3.png') no-repeat center center / cover;
            }
        }
    }

    &__ttl{
        position: relative;
        z-index: 20;
        text-align: center;
        margin-top: 60px;
        display: flex;
        justify-content: center;

        @include g.mq(sp){
            width: 95%;
            margin: 60px auto;

            #ryomai{
                width: 100%;
                margin: 0 auto;
            }
        }
    }

    &__dot{
        font-size: g.$xl_fsz;
        font-weight: g.$fw_bold;
        -webkit-text-stroke: 2px g.$wh_color;
    }

    &__strong{
        display: block;
        font-size: g.$xl_fsz;
        font-weight: g.$fw_bold;
        color: g.$gr_color;
        text-shadow: -2px -2px g.$wh_color;
        -webkit-text-stroke: 0;

    }
}
