@use "global" as g;

.contact{
    padding: 30px 0 80px;

    &.-confirm{
        padding: 150px 0 80px;
    }

    &.-complete{
        padding: 150px 0 80px;
    }

    &__ttl{
        text-align: center;
        font-size: 4rem;
        font-weight: g.$fw_bold;

        &.-complete{
            margin-top: 60px;
        }

        &.-page404{
            margin-top: 60px;
            font-size: 6rem;
        }
    }

    &__caution{
        font-size: g.$xs_fsz;
        line-height: 1.5;
        text-align: center;
        margin-top: 45px;
    }

    &__circles{
        display: flex;
        justify-content: center;
        align-items: center;

        &.-confirm{
            margin-top: 120px;
        }

    }

    &__circle{
        display: inline-flex;
        width: 160px;
        height: 160px;
        justify-content: center;
        align-items: center;
        font-size: 3rem;
        color: #dae7c1;
        border: 4px solid #dae7c1;
        border-radius: 50%;

        @include g.mq(sp){
            width: 115px;
            height: 115px;
            font-size: 2.2rem;
        }

        @include g.mq(xs){
            width: 90px;
            height: 90px;
            font-size: 2rem;
        }

        &.active{
            color: g.$wh_color;
            background-color: g.$gr_color;
            border: none;
        }
    }

    &__bar{
        display: inline-block;
        width: 100px;
        height: 4px;
        background-color: #dae7c1;

        @include g.mq(sp){
            width: 60px;
        }

        @include g.mq(sp){
            width: 40px;
        }

        &.active{
            background-color: g.$gr_color;
        }
    }

    &__lead{
        font-size: g.$sm_fsz;
        text-align: center;
        line-height: 1.5;
        margin-top: 60px;

        @include g.mq(xs){
            font-size: g.$xs_fsz;
        }
    }

    &__form{
        margin-top: 60px;
    }

    &__items {
        width: 850px;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    &__item {
        display: block;

        &:nth-of-type(n + 2){
            margin-top: 40px;
        }
    }

    &__label {
        display: block;
        position: relative;

        label{
            display: inline-block;
            font-size: g.$sm_fsz;
            font-weight: g.$fw_bold;
            padding: 14px 0;
    
            span {
                position: relative;
                font-weight: g.$fw_bold;
    
                &::after {
                    content: "必須";
                    position: absolute;
                    display: inline-block;
                    top: 50%;
                    right: -60px;
                    transform: translateY(-50%);
                    padding: 5px 10px;
                    background-color: #ff7f16;
                    color: g.$wh_color;
                    font-size: 12px;
                    line-height: 1;
                }
            }
        }
    }



    &__input {
        display: block;
        margin-top: 5px;
        font-size: 1.6rem;

        [type="text"], [type="email"],[type="tel"]{
            width: 100%;
            height: 45px;
            border: none;
            box-shadow: none;
            border-radius: 2px;
            background: g.$wh_color;
            padding: 15px 16px;
            appearance: none;
            color: inherit;
            font-family: inherit;
            font-size: g.$xs_fsz;
            border: none;
            line-height: 1;
            margin: 0;
    
            &:focus {
                box-shadow: none;
                outline: 2px solid rgba(0,103,255,1);
            }

            &::placeholder {
                color: #cccccc;
            }
        }

        textarea{
            width: 100%;
            height: 122px;
            border: none;
            box-shadow: none;
            border-radius: 2px;
            background: #fff;
            padding: 15px 16px;
            appearance: none;
            color: inherit;
            font-family: inherit;
            font-size: g.$xs_fsz;
            resize: vertical;
            line-height: 1.4;
    
            &:focus {
                box-shadow: none;
                outline: 2px solid rgba(0,103,255,1);
            }
        }
    }

    &__select {
        width: 100%;
        box-shadow: none;
        border: none;
        padding: 15px 16px;
        margin: 0;
        appearance: none;
        border-radius: 0;
        color: g.$bk_color;
        font-family: inherit;
        font-size: g.$xs_fsz;
        line-height: 1;
        width: 100%;
        background: #fff url("../img/pc/contact/contact-arrow.png") no-repeat right 12px center / 12px 8px;


        &:focus-visible {
            outline: 2px solid rgba(0,103,255,1);
            box-shadow: none;
        }
    }


    &__btn-inner{
        text-align: center;
        margin-top: 48px;
    }

    &__submit-inner{
        text-align: center;
        margin-top: 48px;
        display: flex;
        justify-content: center;

        [type="submit"] {
    
            &:focus-visible{
                outline: 2px solid rgba(0,103,255,1);
            }
        }

        &.-complete{
            margin-top: 120px;
        }
    }

    &__back-btn{
        background-color: #dae7c1;
        color: g.$wh_color;
        border: 2px solid #dae7c1;
        margin-right: 45px;
    }
}