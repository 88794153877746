@use "global" as g;

.c-drawer-icon{
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 200;
    display: inline-block;
    transition: all 0.5s ease 0s;
    opacity: 1;
    padding: 30px 30px;
    border-radius: 0 0 0 10px;
    background-color: g.$gr_color;
    cursor: pointer;

    @include g.mq(sp){
        padding: 25px;
    }

    &.-js-change{
        top: 20px;
        right: 40px;

        @include g.mq(sp){
            right: 20px;
        }
    }


    &.is-active{

        .c-drawer-icon__bar1{
        transform: rotate(-45deg);
        top: 8px;
        }
        
        .c-drawer-icon__bar2{
        display: none;
        }
        
        .c-drawer-icon__bar3{
        transform: rotate(45deg);
        top: 8px;
        }
    }

    &__bars{
        width: 22px;
        height: 20px;
        display: block;
        position: relative;
    }

    &__bar1,
    &__bar2,
    &__bar3{
        position: absolute;
        width: 22px;
        height: 2px;
        background-color: g.$wh_color;
        top: 0;
        left: 0;

        &.is-active{
        background-color: g.$gr_color;
        }
    }

    &__bar1{
        top: 0;
    }

    &__bar2{
        top: 8px;
    }

    &__bar3{
        top: 16px;
    }
}

  // アイコンメニュー---------------------------------------------------------------
.c-drawer{
    width: 60%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 130;
    background: #dae7c1;
    box-shadow: 0 0 3px rgba(#000, 0.16);
    transform: translateX(110%);
    transition: transform 0.5s ease 0s;
    overflow-y: scroll;

    &.is-active{
        transform: translateX(0);
    }

    @include g.mq(sp){
        width: 100%;
    }

    &__list{
        padding: 100px 0 60px;
        text-align: center;
        background-color: g.$bg_color01;

        @include g.mq(sp){
            padding: 60px 0 45px;
        }
    }

    &__item{

        &:last-child{
            margin-top: 30px;
        }
    }

    &__link{
        display: block;
        font-size: g.$sm_fsz;
        padding: 20px;
        position: relative;
        cursor: pointer;

        @include g.mq(sp){
            font-size: g.$xs_fsz;
        }
    }


}


  // 背景--------------------------------------------------------------------------------
.c-drawer-background{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 120;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: none;

    &.is-active{
        display: block;
    }
}


.c-drawer-sns{
    &__items{
        width: 80%;
        margin: 0 auto;
        flex-wrap: wrap;
        background-color: #dae7c1;
        padding-top: 30px;
        display: flex;
        justify-content: space-between;

        @include g.mq(sp){
            padding-top: 30px;
            width: 95%;
        }
    }

    &__item{
        display: inline-flex;
        align-items: center;
        margin-top: 20px;
        font-size: g.$sm_fsz;
        width: 33%;

        @include g.mq(tab){
            width: 50%;
        }

        @include g.mq(sp){
            width: 33%;
        }

        @include g.mq(xs){
            width: 50%;
        }
    }

    &__item-img{
        width: 40px;
        margin-right: 20px;
    }
}