@use "global" as g;

.can{
    padding-bottom: 80px;

    @include g.mq(sp){
        padding-bottom: 45px;
    }

    &__br{
        @include g.mq(xs){
            display: block;
            margin-top: 5px;
        }
    }

    &__inner{
        margin-top: 60px;
        text-align: center;

        @include g.mq(sp){
            margin-top: 45px;
        }
    }

    &__heading{
        font-size: 2.4rem;
        display: inline-block;
        line-height: 1.5;
        background: url('../img/pc/service/ttl-bg.png') no-repeat center center / cover;
        letter-spacing: 2px;

        @include g.mq(xs){
            font-size: 1.8rem;
        }

        span{
            color: g.$gr_color;
            font-weight: g.$fw_bold;
        }
    }

    &__txt{
        width: 897px;
        max-width: 100%;
        margin: 30px auto;
        font-size: g.$sm_fsz;
        line-height: 1.8;
        text-align: center;

        @include g.mq(xs){
            font-size: g.$xs_fsz;
            width: 100%;
        }
    }
}