/*================================================================
Chrome/Firefox/Edgeなど、各ブラウザはそれぞれデフォルトで効くCSSを持っています。
何もしないと見え方が微妙に変わるので、デフォルトのCSSをリセットして
ブラウザごとの表示の差異をなくすために書くのが「リセットCSS」です。
================================================================*/
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html, body, h1, h2, h3, h4, h5, h6, ul, ol, dl, li, dt, dd, p, div, span, img, a, table, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: normal;
  font-size: 100%;
  vertical-align: baseline;
}

header, footer, nav, section, article, aside, figure, figcaption {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
  list-style-type: none;
}