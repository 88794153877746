$gr_color: #5f9d7a;
$bk_color: #333;
$wh_color: #fff;
$bg_color01: #fcf9f1;
$bg_color02: #eff4e3;

$font: 'Noto Sans JP', sans-serif;

$xl_fsz: 6.6rem;
$xl-s_fsz: 5.6rem;
$lg_fsz: 3.6rem;
$md_fsz: 2.2rem;
$sm_fsz: 1.8rem;
$xs_fsz: 1.6rem;

$fw_bold: 700;
$fw_medium: 500;
$fw_regular: 400;