@use "global" as g;

.profile{
    padding-bottom: 80px;

    @include g.mq(sp){
        padding-bottom: 45px;
    }

    &__inner{
        display: flex;
        justify-content: space-around;
        margin-top: 60px;

        @include g.mq(sp){
            margin-top: 45px;
        }
    }

    &__left{
        width: 25%;
        position: relative;

        @include g.mq(sp){
            display: none;
        }
    }

    &__right{
        width: 65%;

        @include g.mq(sp){
            width: 100%;
        }
    }


    &__heading{
        font-size: 2.4rem;
        line-height: 1.5;

        @include g.mq(sp){
            font-size: 2rem;
            text-align: center;
        }

        span{
            color: g.$gr_color;
            font-weight: g.$fw_bold;
        }
    }

    &__txt{
        margin-top: 30px;
        font-size: g.$sm_fsz;
        line-height: 1.5;

        .sp-none{
            font-size: g.$sm_fsz;
            line-height: 1.5;

            @include g.mq(xs){
                display: none;
            }
        }

        .strong{
            color: g.$gr_color;
            font-weight: g.$fw_bold;
        }

        @include g.mq(xs){
            font-size: g.$xs_fsz;
        }
    }
}