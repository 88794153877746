@use "global" as g;

.c-totop{
    display: inline-block;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: fixed;
    z-index: 100;
    bottom: 24px;
    right: 24px;
    background-color: g.$gr_color;

    &__img{
        width: 55px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 14px;
    }

    &__txt{
        display: block;
        text-align: center;
        margin-top: 10px;
        font-size: 1.4rem;
        color: g.$wh_color;
    }
}