@use "global" as g;

.post{
    background: url('../img/pc/top/wood-bg.jpg') no-repeat center center / cover;
    position: relative;
    z-index: 1;
    top: -40px;
    padding: 150px 0 100px;

    &__lead{
        margin-top: 60px;
        font-size: g.$xs_fsz;
        line-height: 1.5;
    }

    &__items{
        width: 100%;
        max-width: 880px;
        margin: 45px auto 0;
    }

    &__item{
        background-color: g.$wh_color;
        padding: 20px 20px 30px 20px;

        @include g.mq(sp){
            padding: 15px 15px 25px 15px;
        }

        &:nth-child(3){
            margin-top: 30px;
        }
    }

    &__heading{
        margin-top: 30px;
        font-size: g.$lg_fsz;
        font-weight: g.$fw_bold;
        color: g.$gr_color;
        text-align: center;

        @include g.mq(sp){
            font-size: 3.2rem;
        }
    }

    &__item-txt{
        margin-top: 15px;
        font-size: 2.4rem;
        line-height: 1.5;
        text-align: center;

        @include g.mq(sp){
            font-size: 1.9rem;
        }
    }

    &__item-inner{
        display: flex;
        justify-content: space-between;
        margin-top: 30px;

        @include g.mq(sp){
            display: block;
        }

        .post__item{
            width: 46.6%;

            @include g.mq(sp){
                width: 100%;

                &:nth-child(2){
                    margin-top: 30px;
                }
            }
        }
    }
}